import { ComponentRef } from '@wix/platform-editor-sdk';

import { connectSearchBox } from '../searchAppController';
import { initSearchBoxSuggestions } from '../searchBox';
import { EditorAppContext } from '../types';
import { getConnectedSearchBoxes } from '../getConnectedSearchBoxes';

export async function reconnectSearchBoxes(
  appContext: EditorAppContext,
  allSearchBoxes: ComponentRef[],
) {
  const connectedSearchBoxes = await getConnectedSearchBoxes(appContext);
  if (allSearchBoxes.length !== connectedSearchBoxes.length) {
    appContext.sentry.captureMessage(
      `There are ${allSearchBoxes.length} search boxes in total, and ${connectedSearchBoxes.length} connected search boxes`,
    );

    // NOTE: currently SearchBox could not be added without search tpa, so unconnected components means, something goes wrong.
    // connect unconnected SearchBoxes to fix incorrect behaviour.
    // TODO: remove this code when resolve issue SER-328
    const unconnectedSearchBoxes = allSearchBoxes.filter(
      (componentRef) => !connectedSearchBoxes.includes(componentRef),
    );

    await Promise.all(
      unconnectedSearchBoxes.map((componentRef) =>
        connectSearchBox(appContext, componentRef),
      ),
    );

    connectedSearchBoxes.push(...unconnectedSearchBoxes);
  }

  await Promise.all(
    connectedSearchBoxes.map((connectedSearchBoxRef) =>
      initSearchBoxSuggestions(appContext, connectedSearchBoxRef),
    ),
  );
}
